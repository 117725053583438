import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "standardizedServices" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "con" }

import { CONST } from '@/types/const'

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_unref(CONST) as any).standardizedServicesList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "services_item pointer",
        onClick: ($event: any) => (_ctx.$router.push(`/${ item.link }`))
      }, [
        _createElementVNode("div", {
          class: "logo",
          style: _normalizeStyle({ background: item.bcolor })
        }, [
          (item.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: item.url
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (item.svg)
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 1,
                color: item.scolor,
                "icon-class": item.svg
              }, null, 8, ["color", "icon-class"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(item.title), 1),
          _createElementVNode("p", null, _toDisplayString(item.con), 1)
        ])
      ], 8, _hoisted_2))
    }), 256))
  ]))
}
}

})